<template>
  <div class="administration">
    <div class="title">
      <div class="flex">
        <img src="@/assets/logo.png" width="10%" height="100%" alt="" />
        <div style="margin-left: 3%;width: 50%;">
          {{ arkDetailData.deptName
          }}<span v-if="arkDetailData.deptName">--</span
          >{{ arkDetailData.name }}
        </div>
      </div>
      <div style="width: 40%;">
        <div class="btn" @click="exit">退出管理</div>
      </div>
    </div>
    <div style="text-align: center;">
      <!-- <img src="@/assets/banner/image.png" width="80%" alt="" /> -->
    </div>
    <div
      style="display: flex; justify-content: space-between;margin: 4% 0;align-items: center;"
    >
      <div style="margin-left: 20px;">
        <a-select
          style="width: 200px;"
          :default-value="'0'"
          v-if="!isCheck"
          @change="handleChange"
        >
          <a-select-option value="0">
            非全选
          </a-select-option>
          <a-select-option value="1">
            全选
          </a-select-option>
          <a-select-option value="2">
            选取所有可取
          </a-select-option>
          <a-select-option value="3">
            选取所有空柜子
          </a-select-option>
          <a-select-option value="4">
            选取所有待清洗
          </a-select-option>
        </a-select>
      </div>
      <div style="display: flex;">
        <div
          class="btn"
          @click="batch"
          style="margin-right: 10px;"
          v-if="isCheck"
        >
          批量管理
        </div>
        <div
          class="btn1"
          @click="cancelbatch"
          style="margin-right: 10px;"
          v-else
        >
          取消批量
        </div>
        <div class="btn" v-if="!isCheck" @click="opencabinet">确认</div>
      </div>
    </div>
    <a-checkbox-group
      style="width: 100%;"
      v-model="checkData"
      @change="onChange"
    >
      <!-- arkDetailData.arkBoxes -->
      <div class="box">
        <div
          class="card"
          v-for="(item, index) in arkDetailData.arkBoxes"
          :key="index"
          @click="edit(item)"
        >
          <a-checkbox
            v-if="!isCheck"
            class="checkbox"
            :value="item"
          ></a-checkbox>
          <div class="num">{{ item.boxNumber }}</div>
          <!-- <div class="num">09</div> -->
          <div class="status">
            <div>状态</div>
            <div
              class="status_text"
              :style="{ background: color[item.status - 1] }"
            >
              <!-- item.status - 1 -->
              {{ text[item.status - 1] }}
            </div>
          </div>
          <div class="open" @click.stop="cabinet(item)">打开柜门</div>
        </div>
      </div>
    </a-checkbox-group>
    <a-modal
      :dialog-style="{ top: '40%' }"
      :visible="modal1Visible"
      :maskClosable="false"
      :footer="null"
      :mask="false"
      :closable="false"
      width="700px"
      @ok="() => setModal1Visible(false)"
      @cancel="cancel()"
    >
      <div style="font-size: 30px;padding: 20px;">
        <div class="modal-title">
          <div style="font-weight: 700;">更改小柜子状态</div>
          <div class="dotted" @click="openbatch" v-if="!isCheck">
            批量打开柜门
          </div>
        </div>
        <div class="text">
          <div v-for="(text, index) in text" :key="text">
            <div
              @click="selectstatus(index)"
              class="btn"
              :style="{
                background: index == status ? color[status] : '',
                color: index == status ? '#fff' : '',
                border: index != status ? '1px dotted #00a6fa' : '',
              }"
            >
              {{ text }}
            </div>
          </div>
        </div>
        <div class="box-btn ">
          <div class="scane" @click="cancel()">取消</div>
          <div class="ok" @click="selectStatus">确定</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'administration',
  components: {},
  data() {
    return {
      arkDetailData: {},
      color: ['#ff9901', '#669901', '#f9564f'],
      text: ['空闲', '可取', '待清洗'],
      checkData: [],
      isCheck: true,
      modal1Visible: false,
      status: null,
    }
  },
  mounted() {
    this.getArk()
    // SystemJsBridge.alert(`页面加载提示`)
  },
  methods: {
    selectStatus() {
      if (this.status == null) {
        return this.$message.warning('请选择更改状态')
      }
      let checkData = []
      this.checkData.forEach((item) => {
        checkData.push(item.boxNumber)
      })
      let taht = this
      taht
        .$post(`/app/xyj-ark/update-box-status`, {
          arkNumber: taht.$route.query.arkNumber,
          boxNumberList: checkData,
          status: this.status + 1,
        })
        .then((res) => {
          if (res.code === 0) {
            taht.$message.success('操作成功')
            taht.modal1Visible = false
            this.checkData = []
            this.getArk()
          } else {
            taht.$message.warning(res.msg)
            this.checkData = []
          }
        })
    },
    // 编辑状态
    edit(e) {
      if (this.isCheck) {
        this.modal1Visible = true
        this.status = e.status - 1
        this.checkData.push(e)
      }
    },
    cancel() {
      this.modal1Visible = false
    },
    selectstatus(i) {
      this.status = i
    },
    // 批量筛选
    handleChange(e) {
      this.checkData = []
      console.log(e, '===')
      if (e == 0) {
        this.checkData = []
      } else if (e == 1) {
        this.arkDetailData.arkBoxes.forEach((item) => {
          this.checkData.push(item)
        })
      } else if (e == 2) {
        this.arkDetailData.arkBoxes.forEach((item) => {
          if (item.status == 2) {
            this.checkData.push(item)
          }
        })
      } else if (e == 3) {
        this.arkDetailData.arkBoxes.forEach((item) => {
          if (item.status == 1) {
            this.checkData.push(item)
          }
        })
      } else if (e == 4) {
        this.arkDetailData.arkBoxes.forEach((item) => {
          if (item.status == 3) {
            this.checkData.push(item)
          }
        })
      } else {
        this.checkData = []
      }
    },
    // 批量打开柜子
    openbatch() {
      let checkData = []
      this.checkData.forEach((item) => {
        checkData.push(item.boxNumber)
      })
      let taht = this
      taht
        .$post(`/app/xyj-ark/open-ark-box`, {
          arkNumber: taht.$route.query.arkNumber,
          boxNumberList: checkData,
        })
        .then((res) => {
          let stauts = LockJsBridge.open(
            Number(res.boxInfo.boardNo),
            Number(res.boxInfo.lockNo)
          )
          if (res.code === 0) {
            this.checkData.forEach((item) => {
              if (stauts === 0 || stauts === 1) {
                taht.$message.success('操作成功')
                taht.modal1Visible = false
              } else {
                this.$message.scucess('开门超时，请联系管理员')
              }
            })
          } else {
            taht.$message.warning(res.msg)
          }
        })
    },
    // 批量操作
    opencabinet() {
      this.status = null
      if (this.checkData.length == 0) {
        return this.$message.warning('请选择更改状态的小柜子')
      } else {
        this.modal1Visible = true
      }
    },
    // 打开柜子
    cabinet(item) {
      let taht = this
      taht.$confirm({
        title: `确定打开${item.boxNumber}号柜子吗?`,
        okText: '确定',
        // okType: 'danger',
        cancelText: '取消',
        onOk() {
          taht
            .$post(`/app/xyj-ark/open-ark-box`, {
              arkNumber: taht.$route.query.arkNumber,
              boxNumberList: [item.boxNumber],
            })
            .then((res) => {
              if (res.code === 0) {
                if (
                  LockJsBridge.open(
                    Number(item.boardNo),
                    Number(item.lockNo)
                  ) === 0
                ) {
                  taht.$message.success('操作成功')
                } else {
                  taht.$message.scucess('开门超时，请联系管理员')
                }
                // taht.$message.success('操作成功')
              } else {
                taht.$message.warning(res.msg)
              }
            })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },
    onChange() {
      console.log(this.checkData)
    },
    batch() {
      this.isCheck = false
    },
    cancelbatch() {
      this.checkData = []
      this.isCheck = true
    },
    // 获取血压柜盒子
    getArk() {
      // alert()
      // SystemJsBridge.alert(`请求接口调用${this.$route.query.arkNumber}`)
      this.$get(
        `/app/xyj-ark/xyj-ark-detail?arkNumber=${
          this.$route.query.arkNumber
        }&t=${Date.parse(new Date())}`
      ).then((data) => {
        // SystemJsBridge.alert(JSON.stringify(data.arkInfo))
        // alert(JSON.stringify(data.arkInfo))
        this.arkDetailData = data.arkInfo
        this.arkDetailData.arkBoxes.forEach((item) => {
          item.check = false
        })
        // if (data.code == 0) {
        // }
      })
    },
    // 退出登录
    exit() {
      let taht = this
      taht.$confirm({
        title: '是否退出当前登录',
        okText: '确定',
        // okType: 'danger',
        cancelText: '取消',
        onOk() {
          sessionStorage.removeItem('Authorization')
          taht.$router.push(`/?arkNumber=${taht.$route.query.arkNumber}`)
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/.ant-select-selection__rendered {
  line-height: 50px;
}
/deep/.ant-select-selection--single {
  height: 50px;
}
.text {
  display: flex;
  justify-content: space-between;
  margin: 80px 0;
  cursor: pointer;
  .btn {
    padding: 4px 10px;
    border-radius: 10px;
  }
}
.dotted {
  padding: 4px 40px;
  color: #00a6fa;
  font-weight: 700;
  cursor: pointer;
  border: 1px dotted #00a6fa;
}
.box-btn {
  padding: 0 20%;
  display: flex;
  justify-content: space-between;
  .scane {
    padding: 10px 40px;
    border: 1px solid #999;
    border-radius: 10px;
    cursor: pointer;
  }
  .ok {
    padding: 10px 40px;
    // border: 1px solid #999;
    background: #00a6fa;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
  }
}
.modal-title {
  display: flex;
  justify-content: space-between;
}
.checkbox {
  position: absolute;
  right: -10px;
  top: -10px;
}
// /deep/.ant-select-selection--single {
//   height: 40px;
//   line-height: 40px;
// }
/deep/.ant-checkbox-inner {
  width: 28px;
  height: 28px;
}
.box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .card {
    width: 21%;
    margin: 0 2%;
    margin-bottom: 2%;
    height: 200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    font-size: 24px;
    padding: 20px;
    .num {
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      position: absolute;
      top: -10px;
      left: -10px;
      background: #00a6fa;
      color: #fff;
      border-radius: 50%;
    }
    .status {
      position: absolute;
      top: 30%;
      width: 84%;
      text-align: center;
      display: flex;
      justify-content: space-between;
      .status_text {
        // background: #00a6fa;
        cursor: pointer;
        padding: 4px 6px;
        color: #fff;
        border-radius: 4px;
      }
    }
    .open {
      position: absolute;
      bottom: 4%;
      left: 14%;
      border-radius: 20px;
      padding: 5px 10px;
      border: 1px dotted #00a6fa;
      cursor: pointer;
    }
  }
}
.administration {
  height: 100vh;
  width: 100vw;
  padding: 20px;
  .title {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .btn {
    // padding: 1rem;
    // width: 20%;
    background: #00a6fa;
    color: #fff;
    padding: 6px 10px;
    text-align: center;
    border-radius: 6px;
    font-size: 32px;
    cursor: pointer;
  }
  .btn1 {
    // padding: 1rem;
    // width: 20%;
    // background: #00a6fa;
    color: #00a6fa;
    padding: 6px 10px;
    text-align: center;
    border-radius: 6px;
    border: 1px dotted #00a6fa;
    font-size: 32px;
    cursor: pointer;
  }
}
</style>
